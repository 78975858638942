<template>
  <div class="page-table only-y mainDiv mb-30">
    <div class="card-base baslikBg animated fadeInUp">
      {{ $t("src.views.apps.rapor.title.genelRaporTitle") }}
      <div class="tanimlamaButon">
        <el-tooltip
          :content="$t('src.views.apps.genel.refresh')"
          :open-delay="500"
          placement="top"
        >
          <el-button
            v-on:click="refreshPage()"
            style="margin-left: 15px !important"
            type="text"
            icon="el-icon-refresh"
          ></el-button>
        </el-tooltip>
      </div>
    </div>

    <div
      class="page-header card-base card-shadow--medium animated fadeInUp"
      v-loading="loading"
      :element-loading-text="
        loading ? $t('src.views.apps.rapor.genelRaporLoading') : ''
      "
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(255, 255, 255, 1)"
    >
      <el-row>
        <!--
                <el-col :lg="12" :md="12" :sm="24" :xs="24">
                <ul class="islemler2">                            
                    <li class="yesil">
                        <i class="mdi mdi-circle-slice-8"></i> % 100
                    </li>
                    <li class="kahverengi">
                        <i class="mdi mdi-circle-slice-8"></i> % 99 - 75
                    </li>
                    <li class="sari">
                        <i class="mdi mdi-circle-slice-8"></i> % 74 - 50
                    </li>
                    <li class="turuncu">
                        <i class="mdi mdi-circle-slice-8"></i> % 49 - 24
                    </li>
                    <li class="pembe">
                        <i class="mdi mdi-circle-slice-8"></i> % 24 - 0
                    </li>
                </ul>                
                </el-col>
              
                <br v-if="!isDesktop">
                <br v-if="!isDesktop">-->

        <div style="display: flex; align-items: center" v-if="isDesktop">
          <el-col
            :lg="16"
            :md="16"
            :sm="8"
            :xs="8"
            style="display: flex; justify-content: flex-start"
            v-if="secilenMusteri != '' && this.projeList != ''"
          >
            <div class="display-end">
              <ul class="islemler">
                <li>
                  <i class="mdi mdi-circle" style="color: #cde990"></i>
                  <span>Biten sözleşmeler</span>
                </li>
                <li>
                  <i class="mdi mdi-circle" style="color: #79b8d180"></i>
                  <span>Devam eden sözleşmeler</span>
                </li>
                <li>
                  <i class="mdi mdi-circle" style="color: #ffe7a0e6"></i>
                  <span>Başlamamış olan sözleşmeler </span>
                </li>
              </ul>
            </div>
          </el-col>

          <el-col
            :lg="8"
            :md="8"
            :sm="8"
            :xs="8"
            style="display: flex; justify-content: flex-end"
            v-if="secilenMusteri != '' && this.projeList != ''"
          >
            <div class="display-end">
              <ul class="islemler">
                <el-button
                  type="text"
                  v-on:click="getGenelRapor()"
                  style="font-family: 'Poppins', sans-serif; font-size: 14px"
                >
                  <span style="color: #4a596a">
                    Raporu indirmek için tıklayınız.
                  </span>
                </el-button>
              </ul>
              &nbsp;
              <el-tooltip :content="'Genel Rapor İndir'" :open-delay="500">
                <el-button
                  type="text"
                  style="color: #ee1010"
                  v-on:click="getGenelRapor()"
                  class="pdf-button"
                >
                  <i class="mdi mdi-download mdi-24px"></i>
                </el-button>
              </el-tooltip>
            </div>
          </el-col>
        </div>

        <div v-if="!isDesktop">
          <br />
          <el-col
            :lg="16"
            :md="16"
            :sm="24"
            :xs="24"
            style="display: flex; justify-content: flex-start"
            v-if="secilenMusteri != '' && this.projeList != ''"
          >
            <div class="display-end" style="display: flex; align-items: center">
              <ul class="islemler2">
                <li>
                  <i class="mdi mdi-circle" style="color: #cde990"></i>
                  <span>Biten sözleşmeler</span>
                </li>
                <li>
                  <i class="mdi mdi-circle" style="color: #79b8d180"></i>
                  <span>Devam eden sözleşmeler</span>
                </li>
                <li>
                  <i class="mdi mdi-circle" style="color: #ffe7a0e6"></i>
                  <span>Başlamamış olan sözleşmeler </span>
                </li>
              </ul>
            </div>
          </el-col>

          <el-col
            :lg="8"
            :md="8"
            :sm="24"
            :xs="24"
            style="display: flex; justify-content: flex-start"
            v-if="secilenMusteri != '' && this.projeList != ''"
          >
            <div class="display-end-sol">
              <el-button
                type="text"
                style="color: #ee1010"
                v-on:click="getGenelRapor()"
                class="pdf-button"
              >
                <i class="mdi mdi-download mdi-24px"></i>
              </el-button>
              <ul class="islemler">
                <el-button
                  type="text"
                  v-on:click="getGenelRapor()"
                  style="font-family: 'Poppins', sans-serif; font-size: 14px"
                >
                  <span style="color: #4a596a">
                    Raporu indirmek için tıklayınız.
                  </span>
                </el-button>
              </ul>
            </div>
          </el-col>
        </div>
      </el-row>

      <el-table
        :data="projeList"
        style="width: 100%"
        id="rapor"
        height="650"
        border
        ref="raporTable"
        v-loading="loading"
        element-loading-text="Yükleniyor..."
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(255, 255, 255, 1)  "
      >
        <el-table-column
          fixed="left"
          label="Proje"
          align="center"
          prop="projeBaslik"
          width="180"
        >
          <template slot="header">
            <div style="color: #00385a">PROJE</div>
          </template>

          <template slot-scope="props">
            <div class="new-line-text">
              {{ props.row.projeBaslik }}
            </div>
          </template>
        </el-table-column>

        <el-table-column
          :fixed="isDesktop ? 'left' : null"
          align="center"
          label="Pursantaj Oranı"
          width="180"
        >
          <template slot="header">
            <div style="color: #00385a">PURSANTAJ ORANI</div>
          </template>

          <template slot-scope="prop">
            <div style="padding: 0 10px 0 10px">
              <el-progress
                :text-inside="true"
                :stroke-width="20"
                :percentage="prop.row.bitmeYuzdesi"
                :color="
                  parseFloat(prop.row.baslamaSureYuzde) -
                    parseFloat(prop.row.bitmeYuzdesi) >
                  5
                    ? 'rgba(251,134,131,0.75)' //yeşil 67C23A
                    : 'rgba(132,205,94,0.75)'
                "
              >
              </el-progress>
            </div>
          </template>
        </el-table-column>

        <el-table-column
          v-for="(item, index) in sozlesmeList"
          :key="index"
          align="center"
          width="200"
        >
          <template slot="header">
            <div class="new-line-text">
              <!-- <div :style="{ minHeight: '80px', maxheigth: '300px', width: '%100', color: `${item.renkKodu} !important`}" class="new-line-text">-->
              {{ item.sozlesmeBaslik }}
            </div>
          </template>

          <template slot-scope="prop">
            <div
              :style="{
                height: '25px',
                width: '100%',
                margin: '0 !important',
                padding: '0 !important',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                background: getBackgroundColor(prop.$index, item),
              }"
            >
              {{ getBaslamaTarihi(prop.$index, item) }}
            </div>
            <div
              :style="{
                height: '25px',
                width: '100%',
                margin: '0 !important',
                padding: '0 !important',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                background: getBackgroundColor(prop.$index, item),
              }"
            >
              {{ getBaslamaTarihi(prop.$index, item) }}
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import notification from "../../../notification";
import functions from "../../../functions";
import wsProvider from "../../../WSProvider/provider/WSProvider";
import projeService from "../../../WSProvider/ProjeService";
import sozlesmeService from "../../../WSProvider/SozlesmeService";
import JQuery from "jquery";
let $ = JQuery;

const { jsPDF } = window.jspdf;

var moment = require("moment");

export default {
  name: "GenelRapor",
  data() {
    return {
      secilenMusteri: "",
      loading: false,
      isDesktop: false,
      toplamSozlesmeListe: [],
      projeList: [],
      sozlesmeList: [],
      selectIcon: "1",
      sizes: 10,
      page: 0,
      postPage: 0,
      total: 4,
      rapor: "",
    };
  },

  created() {
    this.checkDevice();
  },

  mounted() {
    this.loading = true;
    this.getProjeListesi();
    this.getSozlesmeListesi();
    if (this.projeList.length > 0) {
      this.projeList.forEach((e) => {
        e.rgbaColor = hexToRgba(e.renk, 0.4);

        e.sozlesmeler.forEach((el) => {
          el.rgba = hexToRgba(el.renk, 0.4);
        });
      });
    }
    window.addEventListener("resize", this.checkDevice);
  },

  beforeDestroy() {
    window.removeEventListener("resize", this.checkDevice);
  },

  watch: {
    projeList: {
      handler: function (val) {
        if (val.length > 0) {
          this.$nextTick(() => {
            this.$refs.raporTable.doLayout();
          });
        }
      },
      deep: true,
    },
  },

  methods: {
    getBackgroundColor(index, item) {
      // console.log("item => ", item);
      if (this.projeList.length > 0 && this.projeList[index].sozlesmeler) {
        const sozlesmeIndex = this.projeList[index].sozlesmeler.findIndex(
          (el) => el.sozlesmeID == item.sozlesmeID
        );
        if (sozlesmeIndex >= 0) {
          const bitis = this.projeList[index].sozlesmeler[sozlesmeIndex].bitis;
          if (bitis == 2) {
            return "rgba(205, 233, 144, 0.4)";
          } else if (bitis == 1) {
            return "rgba(121,184,209,0.2)";
          } else {
            return "rgba(255, 231, 160,0.2)";
          }
        }
      }
      return "rgba(220,223,228,0.2)";
    },

    getBaslamaTarihi(index, item) {
      if (this.projeList.length > 0 && this.projeList[index].sozlesmeler) {
        const sozlesmeIndex = this.projeList[index].sozlesmeler.findIndex(
          (el) => el.sozlesmeID == item.sozlesmeID
        );
        if (sozlesmeIndex >= 0) {
          const baslamaTarihi =
            this.projeList[index].sozlesmeler[sozlesmeIndex].baslamaTarihi;
          return new Date(baslamaTarihi).toLocaleDateString();
        }
      }
      return "";
    },

    checkDevice() {
      this.isDesktop = window.innerWidth >= 821;
    },

    gunFarki(tarih) {
      var bugun = new Date();
      // console.log(tarih);
      return bugun.getTime() > new Date(tarih).getTime() ? 1 : 0;
    },

    projeAra() {
      this.postPage = 0;
      this.page = 1;
      this.dateVisible = false;
      //siparisNo, adSoyad, telefon, country, city, state, baslangicTarihi, bitisTarihi, durum, baslangic, limit,
      if (this.firmaAdiAra.length > 0 || this.adAra.length > 0) {
        try {
          this.searchLoading = true;
          //kullaniciAdi, adSoyad, mail, telefon, durum, baslangic, limit)
          projeService
            .projeFilter(
              this.firmaAdiAra,
              this.adAra,
              this.selectIcon,
              this.postPage,
              this.sizes
            )
            .then((response) => {
              if (response.status == 200) {
                this.projeList = response.data;
                this.total = response.count;
              }
              localStorage.setItem("userDataBGSurec", response.token);
              this.searchLoading = false;
            })
            .catch((err) => {
              if (err.responseJSON) {
                let error = err.responseJSON;
                if (error.status == 401) {
                  notification.Status(150, this);
                } else if (error.status == 404) {
                  this.projeList = [];
                }
              } else {
                //notification.Status(503, this)
              }
              this.searchLoading = false;
            });
        } catch (e) {
          // notification.Status("danger", this, "An error occurred during the customer search process.")
          this.searchLoading = false;
        }
      } else {
        this.getProjeListesi();
        // this.getProjeSayisi();
      }
    },

    refreshPage() {
      this.page = 1;
      this.postPage = 0;
      this.loading = false;
      this.stateLoading = false;

      // this.getProjeSayisi();
      this.getProjeListesi();
    },

    getGenelRapor() {
      const token = localStorage.getItem("userDataBGSurec");
      const formData = new FormData();
      formData.append("token", token);
      formData.append("serviceName", "proje");
      formData.append("methodName", "genelRapor");

      const xhr = new XMLHttpRequest();
      xhr.open("POST", wsProvider.apiPath + "webservis.php");
      xhr.responseType = "blob";
      xhr.onload = function () {
        const url = window.URL.createObjectURL(xhr.response);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "genelrapor.pdf");
        document.body.appendChild(link);
        link.click();
      };

      xhr.onerror = function () {
        //  console.log("Error occured during the request");
      };

      xhr.send(formData);
    },

    /*
    getGenelRapor() {
      const element = document.querySelector('.cikti'); // HTML içeriği burada var sayılmış
      html2canvas(element).then(canvas => {
        const imgData = canvas.toDataURL('image/png');
        const pdf = new jsPDF();
        const imgProps = pdf.getImageProperties(imgData);
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
        pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
        pdf.save('genel.pdf');
      });
    },*/

    Guncelle(formName) {
      this.$refs[formName].validate((valid, errors) => {
        if (valid) {
          //adSoyad, mail, telefon, projeID
          this.$confirm(
            "Projeyi güncellemek istediğinizden emin misiniz?",
            this.$t("src.views.apps.genel.uyari"),
            {
              confirmButtonText: this.$t("src.views.apps.genel.yes"),
              cancelButtonText: this.$t("src.views.apps.genel.no"),
              type: "warning",
            }
          ).then(() => {
            try {
              this.editDialogLoading = true;
              projeService
                .projeGuncelle(
                  this.projeForm.adSoyad,
                  this.projeForm.eposta,
                  this.projeForm.telefon,
                  this.editDialogData.projeID
                )
                .then((response) => {
                  if (response.status) {
                    this.vazgec(formName);
                    this.getProjeListesi(this.selectIcon);
                  }
                  notification.Status("success", this, response.msg);
                  localStorage.setItem("userDataBGSurec", response.token);
                  this.editDialogLoading = false;
                })
                .catch((err) => {
                  if (err.responseJSON) {
                    let error = err.responseJSON;
                    if (error.status == 401) {
                      notification.Status(150, this);
                    } else notification.Status("errorMsg", this, error.errMsg);
                  } else {
                    //notification.Status(503, this)
                  }
                  this.editDialogLoading = false;
                });
            } catch (e) {
              // notification.Status("danger", this, "An error occurred during the update process.")
              this.editDialogLoading = false;
            }
          });
        } else {
          for (var k in errors) {
            errors[k].forEach((element) => {
              notification.Status("warning", this, element.message);
            });
          }
          this.editDialogLoading = false;
        }
      });
    },

    changeProjeDurum(selection, durum) {
      this.$confirm(
        "Proje durumunu güncellemek istediğinizden emin misiniz?",
        this.$t("src.views.apps.genel.uyari"),
        {
          confirmButtonText: this.$t("src.views.apps.genel.yes"),
          cancelButtonText: this.$t("src.views.apps.genel.no"),
          type: "warning",
        }
      ).then(() => {
        try {
          this.stateLoading = true;
          projeService
            .projeDurumGuncelle(selection.projeID, durum)
            .then((response) => {
              if (response.status == 200) {
                this.getProjeListesi();
              }
              this.stateLoading = false;
              notification.Status("success", this, response.msg);
              localStorage.setItem("userDataBGSurec", response.token);
            })
            .catch((err) => {
              if (err.responseJSON) {
                let error = err.responseJSON;
                if (error.status == 401) {
                  notification.Status(150, this);
                } else notification.Status("errorMsg", this, error.errMsg);
              } else {
                //notification.Status(503, this)
              }
              this.stateLoading = false;
            });
        } catch (e) {
          // notification.Status("success", this, "An error occurred while fetching the customer number.");
          this.stateLoading = false;
        }
      });
    },

    getProjeSayisi() {
      try {
        projeService
          .projeSayisi(this.selectIcon)
          .then((response) => {
            if (response.status == 200) {
              localStorage.setItem("userDataBGSurec", response.token);
            }
            this.total = parseInt(response.data);
          })
          .catch((err) => {
            if (err.responseJSON) {
              let error = err.responseJSON;
              if (error.status == 401) {
                notification.Status(150, this);
              } else if (error.status == 404) {
                this.total = 0;
              }
            } else {
              //notification.Status(503, this)
            }
          });
      } catch (e) {
        // notification.Status("success", this, "An error occurred while fetching the customer number.")
      }
    },

    getProjeListesi() {
      var bugunTarih = new Date();
      try {
        this.loading = true;
        projeService
          .projeListesi(this.selectIcon, this.postPage, 10000, 10)
          .then((response) => {
            localStorage.setItem("userDataBGSurec", response.token);
            if (response.status == 200) {
              this.total = parseInt(response.data.projeSayisi);

              try {
                response.data.forEach((proje) => {
                  proje.bitenyuzdedeger = 0;
                  proje.toplamSozlesme = 0;
                  proje.bitenSozlesme = 0;

                  proje.toplamProjeSuresi = 0;
                  proje.baslamaGecenSure = 0;
                  proje.baslamaSureYuzde = 0;
                  proje.kalanSure = 0;

                  proje.toplamProjeSuresi = getDays(
                    proje.baslangicTarihi,
                    proje.bitisTarihi
                  );
                  //başlangıç Tarihi bugun ve öncesi ise hesaplansın değilse

                  projeBasladiMi(
                    moment().format("YYYY-MM-DD"),
                    proje.baslangicTarihi
                  )
                    ? (proje.baslamaGecenSure = getDays(
                        proje.baslangicTarihi,
                        moment().format("YYYY-MM-DD")
                      ))
                    : (proje.baslamaGecenSure = 0);

                  proje.baslamaSureYuzde = parseFloat(
                    (proje.baslamaGecenSure * 100) / proje.toplamProjeSuresi
                  ).toFixed(1);
                  if (proje.baslamaSureYuzde > 100) {
                    proje.baslamaSureYuzde = 100;
                  }

                  proje.toplamSozlesme = 0;
                  proje.bitenSozlesme = 0;
                  // burada projede bulunan sozlesmeleri oranlarını yuzdelik değerlere ceviriyoruz
                  // yuzde deger = sozlesmeYuzde * 100 / toplamSozlesmelerYuzdeDegerleriToplamlari
                  // 2 foreach donmesinin sebebi once projedeki sözleşmelerin yuzdelerinin toplamlarına ihtiyacımız olmasından! 2.foreach yuzdelik orana cevirmek için.
                  if (
                    proje.sozlesmeler &&
                    proje.sozlesmeler != null &&
                    proje.sozlesmeler != undefined &&
                    proje.sozlesmeler.length > 0
                  ) {
                    proje.sozlesmeler.forEach((sozlesme) => {
                      proje.toplamSozlesme += parseInt(sozlesme.yuzdeDeger);
                      proje.bitenSozlesme += sozlesmeBittiMi(
                        moment().format("YYYY-MM-DD"),
                        sozlesme.isTeslimTarihi
                      )
                        ? parseInt(sozlesme.yuzdeDeger)
                        : 0;
                    });
                  }

                  const bitmeYuzde = parseInt(
                    (proje.bitenSozlesme * 100) / proje.toplamSozlesme
                  );

                  if (isNaN(bitmeYuzde)) proje.bitmeYuzdesi = 0;
                  else proje.bitmeYuzdesi = bitmeYuzde;

                  if (
                    proje.sozlesmeler &&
                    proje.sozlesmeler != null &&
                    proje.sozlesmeler != undefined &&
                    proje.sozlesmeler.length > 0
                  ) {
                    proje.sozlesmeler.forEach((sozlesme) => {
                      proje.toplamSozlesme += parseInt(sozlesme.yuzdeDeger);
                      //console.log(proje)
                      //console.log(proje.toplamSozlesme)
                      sozlesme.baslamaTarihi = new Date(sozlesme.baslamaTarihi);
                      sozlesme.isTeslimTarihi = new Date(
                        sozlesme.isTeslimTarihi
                      );
                      sozlesme.sozlesmebittimi = this.gunFarki(
                        sozlesme.isTeslimTarihi
                      );
                      proje.bitenSozlesme +=
                        sozlesme.sozlesmebittimi == 1
                          ? parseFloat(sozlesme.yuzdeDeger)
                          : 0;
                      proje.bitenyuzdedeger = parseFloat(
                        (proje.bitenSozlesme * 100) / proje.toplamSozlesme
                      ).toFixed(1);

                      if (
                        new Date(sozlesme.baslamaTarihi).getTime() <
                          bugunTarih.getTime() &&
                        new Date(sozlesme.isTeslimTarihi).getTime() <
                          bugunTarih.getTime()
                      ) {
                        sozlesme.bitis = 2;
                      } else if (
                        new Date(sozlesme.baslamaTarihi).getTime() <
                          bugunTarih.getTime() &&
                        new Date(sozlesme.isTeslimTarihi).getTime() >
                          bugunTarih.getTime()
                      ) {
                        sozlesme.bitis = 1;
                      } else {
                        sozlesme.bitis = 0;
                      }
                    });
                  }
                });
                //console.log(proje)
              } catch (e) {
                console.error("e  =>  ", e);
              }
              // console.log(" response =>    ", response.data);
              this.projeList = response.data;

              // console.log("projeList => ", this.projeList);
              if (this.projeList.length > 0) {
                this.projeList.forEach((e) => {
                  e.rgbaColor = hexToRgba(e.renk, 0.4);

                  e.sozlesmeler.forEach((el) => {
                    el.rgba = hexToRgba(el.renk, 0.4);
                  });
                });
              }

              this.total = parseInt(response.count);
            }
            this.loading = false;
          })
          .catch((err) => {
            if (err.responseJSON) {
              let error = err.responseJSON;
              if (error.status == 401) {
                notification.Status(150, this);
              } else if (error.status == 404) {
                this.projeList = [];
              } else notification.Status("errorMsg", this, error.errMsg);
            } else {
              //notification.Status(503, this)
            }
            this.loading = false;
          });
      } catch (e) {
        // notification.Status("success", this, "An error occurred while fetching the customer list.")
        this.loading = false;
        console.log(e);
      }
    },

    getSozlesmeListesi() {
      try {
        this.loading = true;
        sozlesmeService
          .sozlesmeListesi(this.selectIcon, this.postPage, 1000)
          .then((response) => {
            localStorage.setItem("userDataBGSurec", response.token);
            if (response.status == 200) {
              this.total = parseInt(response.data.sozlesmeSayisi);
              response.data.forEach((el) => {
                /*el.dogumSaati = el.dogumTarihi != '0000-00-00 00:00:00' ? moment(el.dogumTarihi).format('hh:mm:ss') : ''
                              el.dogumGunu = el.dogumTarihi != '0000-00-00 00:00:00' ? moment(el.dogumTarihi).format('YYYY-MM-DD') : ''
                              el.kayitSaati = el.kayitTarih != '0000-00-00 00:00:00' ? moment(el.kayitTarih).format('hh:mm:ss') : ''
                              el.kayitGunu = el.kayitTarih != '0000-00-00 00:00:00' ? moment(el.kayitTarih).format('YYYY-MM-DD') : ''*/
              });
              this.sozlesmeList = response.data;
              this.total = parseInt(response.count);
            }
            this.loading = false;
          })
          .catch((err) => {
            if (err.responseJSON) {
              let error = err.responseJSON;
              if (error.status == 401) {
                notification.Status(150, this);
              } else if (error.status == 404) {
                this.sozlesmeList = [];
              } else notification.Status("errorMsg", this, error.errMsg);
            } else {
              //notification.Status(503, this)
            }
            this.loading = false;
          });
      } catch (e) {
        // notification.Status("success", this, "An error occurred while fetching the customer list.")
        this.loading = false;
      }
    },

    vazgec(formName) {
      this.$refs[formName].resetFields();
      this.editDialog = false;
      this.loading = false;
      this.stateLoading = false;
    },

    startUpdate() {
      this.projeForm.adSoyad = this.editDialogData.adSoyad;
      this.projeForm.eposta = this.editDialogData.mail;
      this.projeForm.telefon = this.editDialogData.telefon;
      this.projeForm.tc = this.editDialogData.tc;
      // this.projeForm.dogumTarihi = moment(this.editDialogData.dogumTarihi).format("DD-MM-YYYY");
    },

    updatePage(data) {
      this.$store.commit("changeProjeUpdateData", data.projeID);
      functions.routeSayfa("Proje Güncelle", this);
    },

    routeProje(name, scope) {
      if (scope) {
        this.$store.commit("changeProjeUpdateData", scope);
      }
      functions.routeSayfa(name, this);
    },

    indexMethod(index) {
      return (this.page - 1) * 10 + index + 1;
    },

    handleSizeChange(val) {
      this.sizes = val;
      this.getProjeListesi();
    },

    handleChange(event) {
      this.selectIcon = event;
      this.page = 1;
      this.postPage = 0;
      this.getProjeListesi();
      document
        .querySelector(".scrollable")
        .scrollTo({ top: 0, behavior: "smooth" });
      this.firmaAdiAra = "";
      this.telefonAra = "";
    },

    pageChange(val) {
      this.postPage = (val - 1) * this.sizes;
      this.getProjeListesi();
    },
  },
};

function hexToRgba(hex, alpha) {
  // hex kodunu parçalara ayır
  let r = parseInt(hex.substring(1, 3), 16);
  let g = parseInt(hex.substring(3, 5), 16);
  let b = parseInt(hex.substring(5, 7), 16);
  // rgba formatına çevir
  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
}

function getDays(tarih1, tarih2) {
  // Tarihleri Moment objelerine dönüştür ve gün farkını hesapla
  const tarih1Moment = moment(tarih1);
  const tarih2Moment = moment(tarih2);
  const gunFarki = tarih2Moment.diff(tarih1Moment, "days");

  // Sonucu döndür
  return gunFarki;
}

function sozlesmeBittiMi(tarih1, tarih2) {
  // Tarihleri Moment objelerine dönüştür
  const momentTarih1 = moment(tarih1);
  const momentTarih2 = moment(tarih2);

  // Tarih1 tarih2'den sonra mı?
  return momentTarih1.isAfter(momentTarih2);
}

// bugun daha önceyse proje başlamadı demek
// bugun daha sonraysa true donmelı
function projeBasladiMi(bugun, baslangicTarihi) {
  const momentBugun = moment(bugun);
  const momentBaslangic = moment(baslangicTarihi);

  return momentBugun.isAfter(momentBaslangic);
}
</script>

<style lang="scss">
#rapor {
  .el-table__cell {
    .cell {
      padding: 0 !important;
    }
  }

  .el-progress-bar__innerText {
    color: white !important;
  }

  thead th {
    background: #c7e3f6 !important;
    color: #00385a;
  }
}

.btn-ping {
  position: relative;
  width: 20px;
  height: 20px;
  margin: auto;
}

.mapIcon,
.iconPing {
  // background: #00ade6;
  border-radius: 50%;
  position: absolute;
}

.mapIcon {
  /*  fix for iconPing border  */
  margin: 45%;
  width: 50%;
  height: 50%;
}

.iconPing {
  width: 100%;
  height: 100%;
  // background: #333;

  /* if changing the border width, change the margin on mapIcon */
  border: 4px groove #00ade6;
  border-style: double dotted;
  animation-name: ping;
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
  /* W3C and Opera */
  -moz-animation-iteration-count: infinite;
  /* Firefox */
  -webkit-animation-iteration-count: infinite;
  /*Safari and Chrome*/
}

.ping2 {
  opacity: 0;
  animation-delay: 1.3s;
  border-style: dotted double;
}

.ping3 {
  opacity: 0;
  animation-delay: 2.3s;
}

@keyframes ping {
  from {
    display: block;
    transform: scale(0.4);
    opacity: 1;
  }

  to {
    transform: scale(1);
    opacity: 0;
  }
}
</style>
